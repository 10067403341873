import React from "react"
import Layout from "../components/layout"
import Posts from "../components/layout/Posts"
import { graphql, StaticQuery } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { sha256, sha224 } from "js-sha256"
import rodoFile from "../../static/RODO_w_Sempre_2021.pdf"
import policyFile from "../../static/Regulamin_strony_Fundacji_Sempre_a_Frente_2021.pdf"

export default class HelpUs extends React.Component {
    state = {
        signature: "",
        value: 0,
        buttonDisabled: true,
        buttonSendDisabled: true,
        step: 1,
        agreePolicy: false,
        agreeRodo: false,
        buyerEmail: "",
        buyerFirstName: "",
        buyerLastName: "",
    }

    componentDidMount() {
    }

    formValidate() {
        const s = this.state

        if (s.agreePolicy && s.agreeRodo && (s.buyerLastName.length > 2) && (s.buyerFirstName.length > 2) && (s.buyerEmail.length > 8)) {
            this.setState({ buttonSendDisabled: false })
        }
        else {
            this.setState({ buttonSendDisabled: true })
        }
    }

    clearCheckboxes() {
        this.radio1.checked = false
        this.radio2.checked = false
        this.radio3.checked = false
        this.setState({ buttonDisabled: true })
    }

    fillForm(value) {
        this.setState({ value: value, buttonDisabled: false })
        this.input.value = ""
    }

    generateSignature(e) {
        e.preventDefault()
        const id = "3661591"
        let content = ""

        for (const element of e.target.elements) {
            if (!element.value.includes("signature") && !element.name.includes("OpenPayu")) {
                if (!element.value.includes("https://") && !element.name.includes("notify")) {
                    if(element.name.includes("email")) {
                        content += element.name + "=" + element.value.replace("@", "%40")
                    }
                    else {
                        content += element.name + "=" + encodeURI(element.value)
                    }
                    content += "&"
                }
                else {
                    content += "notifyUrl=https%3A%2F%2Fsempre-a-frente.secure.force.com%2Fservices%2Fapexrest%2Fpayu%2Forder&"
                }
            }
        }

        content = content.substring(0, content.length - 2)
        content += "ac4ed13f172a5f8b3b3cbf929ef11101"

        let result = "sender=" + id + ";algorithm=SHA-256;"
        result = result + "signature=" + sha256(content)

        this.setState({ signature: result })
    }

    sendForm(e) {
        e.preventDefault()
        this.setState({buttonSendDisabled: true})
        this.generateSignature(e)
        setTimeout(() => { this.form.submit(e) }, 1000);
    }

    onInputChange(e) {
        this.clearCheckboxes()
        this.setState({ value: e.target.value * 100 })
        if (e.target.value.length > 0) {
            this.setState({ buttonDisabled: false })
        }
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query HelpQuery {
                        datoCmsHelpPage {
                          seoMetaTags {
                            ...GatsbyDatoCmsSeoMetaTags
                          }
                          bannerHeadline
                            bannerTextNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                            payuHeadline
                            payuTextNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                        contentNode {
                            childMarkdownRemark{
                                html
                            }
                          }
                        }
                    }
                `}
                render={data => (
                    <Layout className="home">
                        <HelmetDatoCms seo={data.datoCmsHelpPage.seoMetaTags} />
                        <div className="payment-page">
                            <div className="content">
                                <div className="payment-page__text">
                                    <h1>{data.datoCmsHelpPage.bannerHeadline}</h1>
                                    <pre dangerouslySetInnerHTML={{
                                        __html: data.datoCmsHelpPage.bannerTextNode.childMarkdownRemark.html,
                                    }}
                                    />
                                </div>
                                <div className="payment-page__box">
                                    {this.state.step == 1 &&
                                    <div>
                                        <h1>{data.datoCmsHelpPage.payuHeadline}</h1>
                                        <pre dangerouslySetInnerHTML={{
                                            __html: data.datoCmsHelpPage.payuTextNode.childMarkdownRemark.html,
                                        }}
                                        />
                                        <div className="buttons">
                                            <div className="flex">
                                                <label className="radio">
                                                    <input type="radio" value="2000" name="paymentValue" ref={(el) => { this.radio1 = el }} onChange={() => this.fillForm(2000)} />
                                                    <span>20 zł</span>
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="4000" name="paymentValue" ref={(el) => { this.radio2 = el }} onChange={() => this.fillForm(5000)} />
                                                    <span>50 zł</span>
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="6000" name="paymentValue" ref={(el) => { this.radio3 = el }} onChange={() => this.fillForm(10000)} />
                                                    <span>100 zł</span>
                                                </label>
                                                <input ref={(el) => { this.input = el }} type="number" min="1" placeholder="Inna kwota" onFocus={() => this.clearCheckboxes()} onChange={(e) => this.onInputChange(e)} />
                                            </div>
                                            <div className="flex">
                                                <label className="custom-radio">
                                                    <input ref={(el) => { this.type1 = el }} checked={true} type="radio" name="payment-type" />
                                                    <span>Jednorazowo</span>
                                                </label>
                                                <label className="custom-radio">
                                                    <input ref={(el) => { this.type2 = el }} type="radio" name="payment-type" />
                                                    <span>Miesięcznie</span>
                                                </label>
                                            </div>
                                            <button type="submit" className="btn-yellow" disabled={this.state.buttonDisabled} onClick={() => this.setState({ step: 2 })}>WSPIERAM <i className="icon-heart"></i></button>
                                        </div>
                                    </div>
                                    }
                                    {this.state.step == 2 &&
                                    <div>
                                        <h1>Podaj swoje dane</h1>
                                        <div className="buttons">
                                            <div className="flex space">
                                                <div className="input">
                                                    <input type="text" minLength="3" placeholder="Imię *" onBlur={(e) => this.setState({ buyerFirstName: e.currentTarget.value }, () => this.formValidate())} required />
                                                </div>
                                                <div className="input">
                                                    <input type="text" minLength="3" placeholder="Nazwisko *" onBlur={(e) => this.setState({ buyerLastName: e.currentTarget.value }, () => this.formValidate())} required />
                                                </div>
                                            </div>
                                            <div className="input">
                                                <input type="email" minLength="10" placeholder="Email *" onBlur={(e) => this.setState({ buyerEmail: e.currentTarget.value }, () => this.formValidate())} required />
                                            </div>
                                            <div>
                                                <label className="checkbox">
                                                    <input type="checkbox" checked={this.setState.agreePolicy} onChange={(e) => this.setState({ agreePolicy: e.currentTarget['checked'] }, () => this.formValidate())} />
                                                    <span>Akceptuję <a href={policyFile} target="_blank">Regulamin strony i wpłat</a> *</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="checkbox">
                                                    <input type="checkbox" checked={this.setState.agreeRodo} onChange={(e) => this.setState({ agreeRodo: e.currentTarget['checked'] }, () => this.formValidate())} />
                                                    <span>Zgoda na przetwarzanie danych <a href={rodoFile} target="_blank">RODO</a> *</span>
                                                </label>
                                            </div>
                                        </div>
                                        <form ref={(el) => { this.form = el }} onSubmit={(e) => this.sendForm(e)} method="post" action="https://secure.payu.com/api/v2_1/orders">
                                            <input type="hidden" name="buyer.email" value={this.state.buyerEmail} />
                                            <input type="hidden" name="buyer.firstName" value={this.state.buyerFirstName} />
                                            <input type="hidden" name="buyer.lastName" value={this.state.buyerLastName} />
                                            <input type="hidden" name="currencyCode" value="PLN" />
                                            <input type="hidden" name="customerIp" value="123.123.123.123" />
                                            <input type="hidden" name="description" value="Darowizna_na_cele_statutowe" />
                                            <input type="hidden" name="merchantPosId" value="3661591" />
                                            <input type="hidden" name="notifyUrl" value="https://sempre-a-frente.secure.force.com/services/apexrest/payu/order" />
                                            <input type="hidden" name="OpenPayu-Signature" value={this.state.signature} />
                                            <input type="hidden" name="products[0].name" value="Darowizna_na_cele_statutowe" />
                                            <input type="hidden" name="products[0].quantity" value="1" />
                                            <input type="hidden" name="products[0].unitPrice" value={this.state.value} />
                                            <input type="hidden" name="totalAmount" value={this.state.value} />
                                            <button ref={(el) => { this.btnSend = el }} disabled={this.state.buttonSendDisabled} type="submit" className="btn-yellow" >WPŁAĆ <i className="icon-heart"></i></button>
                                        </form>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="page">
                                <div className="page__content">
                                    {data.datoCmsHelpPage.contentNode &&
                                    <pre className="justify" dangerouslySetInnerHTML={{
                                        __html: data.datoCmsHelpPage.contentNode.childMarkdownRemark.html,
                                    }}/>
                                    }
                                </div>
                            </div>
                            <Posts />
                        </div>
                    </Layout>
                )}
            />
        )
    }
}